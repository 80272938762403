export enum AuthEnum {
    LOGIN_SUCCESS = 'success',
    LOGIN_FAIL = 'fail',
    LOGGING = 'logging',
    LOGOUT = 'logout'
}

export enum AuthMethodEnum {
    PASSWORD = 'password',
    GOOGLE = 'google'
}

export type AuthMethodType = {
    method: AuthMethodEnum.PASSWORD | AuthMethodEnum.GOOGLE
    methodData: { username?: string; password?: string; id_token?: string }
    isOnline?: boolean
}

type PayloadType = {
    message: string
    statusCode: number
    user?: {}
}

interface LoginSuccess {
    type: AuthEnum.LOGIN_SUCCESS
    payload: PayloadType
}

interface LoginFail {
    type: AuthEnum.LOGIN_FAIL
    payload: PayloadType
}

interface Logging {
    type: AuthEnum.LOGGING
    payload?: PayloadType
}

interface Logout {
    type: AuthEnum.LOGOUT
    payload?: PayloadType
}

export type AuthActionType = LoginSuccess | LoginFail | Logging | Logout
