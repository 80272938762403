import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    Container,
    Col,
    Row,
    TextInput,
    Password,
    Button,
    Spinner,
    ToggleButton
} from '../../components'
import { useOnChange, useBindDispatch, useNetworkConnection } from 'hooks'
import { constants, setTheme, isAuthenticated } from 'helpers'
import './login.scss'
import i18n from '../../helpers/i18n'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleIcon, HandShakeLogo } from 'assets'
import { AuthMethodEnum } from 'storeTypes'
import PropTypes from 'prop-types'
import { useBaseNavigation } from 'hooks/useBaseNavigation'

export const Login = ({ authHandler }) => {
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    const [isSpinner, setIsSpinner] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const navigate = useBaseNavigation()
    const authenticated = isAuthenticated()
    const { inputText, onChangeHandler, resetTextInput } = useOnChange({
        username: '',
        password: ''
    })
    const { loginDispatch, navbarHandler } = useBindDispatch()
    const loginState = useSelector((store) => store.auth)

    const isTrainingModeOn =
        process.env.REACT_APP_CONNECT_TRAINING_MODE === 'true'

    const { isOnline } = useNetworkConnection()

    // hide menu for login page
    useEffect(() => {
        navbarHandler(
            authenticated ? constants.SHOW_NAVBAR : constants.HIDE_NAVBAR
        )
        if (!authenticated) {
            setTheme(
                isTrainingModeOn
                    ? constants.THEME_NAMES.TRAINING
                    : constants.THEME_NAMES.DEFAULT
            )
            i18n.changeLanguage('fr')
        }
    }, [])

    // don't show login page for authenticated users
    useEffect(() => {
        if (authenticated) {
            navigate('/home')
        }
    }, [])

    useEffect(() => {
        setIsSpinner(loginState.loggingIn)
        if (loginState.response?.statusCode === 200) {
            authHandler(true)
            navigate('/home')
            resetTextInput()
            // show menu for logged in user
            navbarHandler(constants.SHOW_NAVBAR)
        } else if (loginState.response?.statusCode >= 400) {
            errorHandler(loginState.response.message)
        }
    }, [loginState])

    const handleGoogleLogin = ({ access_token: accessToken }) => {
        loginDispatch({
            method: AuthMethodEnum.GOOGLE,
            methodData: { id_token: accessToken },
            isOnline
        })
    }

    const googleLoginConfig = useGoogleLogin({ onSuccess: handleGoogleLogin })

    // handle login data
    const loginHandler = (e) => {
        const { username, password } = inputText
        e.preventDefault()
        if (!username || !password) {
            errorHandler(t('login.noInput'))
            return
        }
        setError(false)
        setErrMsg('')
        loginDispatch({
            method: AuthMethodEnum.PASSWORD,
            methodData: { ...inputText },
            isOnline
        })
    }

    // no input form validation
    const errorHandler = (message) => {
        setError(true)
        setErrMsg(t(message))
    }

    const handleTrainingToggle = (event) => {
        window.location.href = event.target.checked
            ? process.env.REACT_APP_TRAINING_URL
            : process.env.REACT_APP_PRODUCTION_BASE_URL
    }

    const loginButtonEnabled =
        inputText.username?.length &&
        inputText.password?.length &&
        !loginState.loggingIn

    return (
        <div>
            <Container className="login-container">
                <Row>
                    <Col md={12} className="login-top-section">
                        <div className="training-toggle">
                            <ToggleButton
                                toggleText={t('train').toUpperCase()}
                                className="toggle-training"
                                onToggle={handleTrainingToggle}
                                defaultValue={isTrainingModeOn ? 'on' : 'off'}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {error && <p className="login-error">{errMsg}</p>}
                        <div className="logo-parent">
                            <HandShakeLogo />
                            <h2 className="header-welcome login-welcome">
                                {t('login.welcomeHeader')}
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="form-parent login-form">
                            <form onSubmit={loginHandler}>
                                <div className="formgroup">
                                    <TextInput
                                        id="username"
                                        name="username"
                                        value={inputText.username}
                                        onChange={onChangeHandler}
                                        placeholder={t(
                                            'login.usernamePlaceholder'
                                        )}
                                        className={`login-input${
                                            error
                                                ? '--invalid'
                                                : inputText.username?.length > 0
                                                ? '--valid'
                                                : ''
                                        }`}
                                    />
                                </div>
                                <div className="formgroup">
                                    <Password
                                        icon
                                        id="password"
                                        name="password"
                                        value={inputText.password}
                                        placeholder={t(
                                            'login.passwordPlaceholder'
                                        )}
                                        onChange={onChangeHandler}
                                        className={`login-input${
                                            error
                                                ? '--invalid'
                                                : inputText.password?.length > 0
                                                ? '--valid'
                                                : ''
                                        }`}
                                    />
                                </div>
                                <div className="formgroup">
                                    <Button
                                        style="primary"
                                        size="large"
                                        className="login-button"
                                        disable={!loginButtonEnabled}
                                        id="login-button"
                                    >
                                        {isSpinner ? (
                                            <Spinner size="40" />
                                        ) : (
                                            t('login.login')
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="fc-section">
                <h2 className="fc-title">{t('login.fc-title')}</h2>
                <div className="formgroup">
                    <Button
                        onClick={() => googleLoginConfig()}
                        icon={<GoogleIcon />}
                        iconPos="left"
                        ariaLabel="google-signin"
                        className="google-btn"
                    >
                        {t('login.googleLogin')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    authHandler: PropTypes.func.isRequired
}
