import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
    amountFormatter,
    constants,
    getInteractionsFromClientStatus,
    interactionOutcomeStyles,
    routes,
    getConnectUser
} from '../../helpers'
import { camelCase } from 'lodash'
import { Button } from '../button/Button'
import { FlagIcon, VisitedHandshakeIcon, NotVistedIcon } from '../../assets'
import './card.scss'
import { t } from 'i18next'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { findPermission } from '../../helpers/findPermission'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ClientInteractions, InteractionOutcomes } from 'types'
import { findInteraction } from 'helpers/interactions/interactionHelper'
import { ClientDetailsCard } from 'components/client/ClientDetailsCard'
import { ClientPhone } from 'components/client/ClientPhone'
import { Modal } from 'components/modal/Modal'
import { ClientFullDetailsCard } from 'components/client/ClientFullDetailsCard'
import {
    getAgeProfile,
    getGenderAgeProfile
} from 'pages/village/helpers/villageClientHelpers'

export const CardClientDetails = ({
    children,
    clientCode,
    clientPhone,
    otherClientData,
    clientPage = constants.CLIENT_TYPES.CURRENT_CLIENT,
    isClientDuplicate = false,
    clientStatus,
    interactionHandler,
    balance,
    ...props
}) => {
    const { villageId } = useParams()
    const { interactions } = useSelector(
        ({ interactions }) => interactions[villageId] || {}
    )
    const { recentCardPayments } = useSelector((state) => state.cardPayment)

    const [displayMore, setDisplayMore] = useState(false)
    const [displayInteractions, setDisplayInteractions] = useState(false)
    const [clientInteractionStatus, setClientInteractionStatus] = useState()
    const [clientInteraction, setClientInteraction] = useState({})

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const {
        UPDATE_CLIENT,
        USER_PERMISSIONS,
        CLIENT_DETAIL_LOCAL_STORE,
        CLIENT_FOR_ENROLLMENT_STORE,
        CLIENT_VISIT_INTERACTION
    } = constants
    const userPermissions = useGetStorage(USER_PERMISSIONS)
    const localClientData = JSON.parse(
        localStorage.getItem(CLIENT_DETAIL_LOCAL_STORE)
    )

    const canRecordInteractions = findPermission(
        userPermissions,
        constants.CAN_RECORD_INTERACTIONS
    )

    const canCallClient = findPermission(
        userPermissions,
        constants.CAN_CALL_CLIENT
    )

    const showInteractionsButton = !isClientDuplicate
    const storedClientVisitInteraction = useGetStorage(CLIENT_VISIT_INTERACTION)
    const veData = getConnectUser()
    const { paymentNotificationHandler } = useBindDispatch()

    useEffect(() => {
        if (
            interactions?.length > 0 &&
            storedClientVisitInteraction?.client_code !== clientCode
        ) {
            const foundInteraction = findInteraction(interactions, clientCode)

            if (foundInteraction) {
                setClientInteraction(foundInteraction)
                localStorage.setItem(
                    constants.CLIENT_VISIT_INTERACTION,
                    JSON.stringify(foundInteraction)
                )
            }
        } else if (storedClientVisitInteraction?.client_code === clientCode) {
            setClientInteraction({ ...storedClientVisitInteraction })
        }
    }, [interactions?.length, clientCode])

    useEffect(() => {
        if (clientInteractionStatus?.className !== 'duplicate') {
            if (isClientDuplicate) {
                setClientInteractionStatus(
                    interactionOutcomeStyles[InteractionOutcomes.DUPLICATE]
                )
            } else {
                setClientInteractionStatus(
                    interactionOutcomeStyles[
                        clientInteraction?.outcome || 'default'
                    ]
                )
            }
        }
    }, [isClientDuplicate, clientInteraction])

    useEffect(() => {
        paymentNotificationHandler(veData.username)
    }, [recentCardPayments?.length])

    const formatPhone = amountFormatter(clientPhone, '')

    const modifyClickHandler = () => {
        // include form type and village info in client data
        const { veVillages } = veData
        // get village name
        const { name: villageName } = veVillages.find(
            ({ sf_id: sfId }) => sfId === localClientData.village_id
        ) || { name: '' }
        localClientData.form_type = 'modification'
        localClientData.village_name = villageName
        localStorage.setItem(
            CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify(localClientData)
        )

        localStorage.setItem(
            CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...localClientData,
                modification: constants.CLIENT_MODIFICATION_TYPES.DETAILS
            })
        )
        return navigate(`/${routes.enroll}/${villageId}?from=${pathname}`)
    }
    const displayEditBtn =
        findPermission(userPermissions, UPDATE_CLIENT) && !isClientDuplicate

    const handleInteractionBtnClick = () => {
        setDisplayInteractions(!displayInteractions)
    }

    const filterOutDuplicateFlagInteractionWhen = useCallback(
        (conditionIsTrue, inInteractions) =>
            conditionIsTrue
                ? inInteractions?.filter(
                      (interaction) =>
                          interaction.name !== ClientInteractions.DUPLICATE_FLAG
                  )
                : inInteractions,
        [clientInteraction, balance]
    )

    const isPaymentPending = recentCardPayments?.length > 0

    const buildInteractionList = () => {
        const interactions = getInteractionsFromClientStatus(clientStatus)

        const isBalanceEligible = balance && balance !== 0
        const condition = isBalanceEligible || isPaymentPending
        const filteredInteractions = filterOutDuplicateFlagInteractionWhen(
            condition,
            interactions
        )

        return filteredInteractions.map(({ name, outcome, icon }, idx) => {
            const buttonID = `interaction-${name.replace(/\s+/g, '-')}-btn`
            return (
                <Button
                    key={idx}
                    className={[
                        buttonID +
                            (clientPage ===
                            constants.CLIENT_TYPES.PREVIOUS_CLIENT
                                ? ' interaction-btn-small'
                                : ''),
                        canRecordInteractions ? '' : 'disable-interaction-btn'
                    ].join(' ')}
                    style="primary"
                    iconPos="left"
                    icon={icon}
                    onClick={() => {
                        if (canRecordInteractions) {
                            interactionHandler({ name, outcome })
                            setDisplayInteractions(false)
                        }
                    }}
                    data-testid={buttonID}
                    id={buttonID}
                >
                    {t('clientDetails.' + camelCase(name))}
                </Button>
            )
        })
    }

    const profileIcon =
        clientPage === constants.CLIENT_TYPES.PREVIOUS_CLIENT ? (
            isClientDuplicate ? (
                <FlagIcon width="50" height="50" color="red" />
            ) : clientInteractionStatus?.className === 'not_interested' ? (
                <VisitedHandshakeIcon color="#16A34A" width="70" height="70" />
            ) : clientInteractionStatus?.className === 'follow_up' ? (
                <VisitedHandshakeIcon color="#16A34A" width="70" height="70" />
            ) : (
                <NotVistedIcon />
            )
        ) : isClientDuplicate ? (
            <FlagIcon width="50" height="50" color="red" />
        ) : null

    const genderIconUrl = getGenderAgeProfile(
        t,
        otherClientData[t('gender')],
        otherClientData[t('age')]
    )

    return (
        <div {...props}>
            <div
                className="card-client-detail--top"
                onClick={() => setDisplayMore(true)}
                aria-label="client-details-card"
            >
                <ClientDetailsCard
                    profileIcon={profileIcon}
                    genderIconUrl={genderIconUrl}
                    client={{
                        fullname: children,
                        client_code: clientCode,
                        gender: otherClientData[t('gender')],
                        ageGroup: getAgeProfile(otherClientData[t('age')])
                    }}
                />
            </div>
            <div className="card-client-detail--contact-interactions">
                <ClientPhone
                    clientPhoneInfo={{ formatPhone, clientPhone }}
                    permissions={{
                        isClientDuplicate,
                        canCallClient
                    }}
                />
            </div>
            {showInteractionsButton && (
                <>
                    <Button
                        className="card-client-detail--button-interactions"
                        style="primary"
                        iconPos="right"
                        size="xl"
                        onClick={handleInteractionBtnClick}
                        data-testid="interactions-btn"
                        id="interactions-btn"
                    >
                        {t('clientDetails.interactions')}
                    </Button>
                    <div
                        className={[
                            'interactions-container',
                            displayInteractions ? 'show' : 'hide'
                        ].join('--')}
                    >
                        {buildInteractionList()}
                    </div>
                </>
            )}
            <Modal
                showModal={displayMore}
                position={'bottom'}
                closeClickHandler={() => setDisplayMore(false)}
                showCloseIconBtn
                testId="client-full-details-modal"
            >
                <ClientFullDetailsCard
                    genderIconUrl={genderIconUrl}
                    client={{ fullname: children, client_code: clientCode }}
                    otherClientData={otherClientData}
                    btnTitle={t('modify')}
                    displayEditBtn={displayEditBtn}
                    modifyClickHandler={modifyClickHandler}
                />
            </Modal>
        </div>
    )
}

CardClientDetails.propTypes = {
    children: PropTypes.string,
    clientCode: PropTypes.string,
    clientPhone: PropTypes.string,
    balance: PropTypes.number,
    otherClientData: PropTypes.object,
    preCertified: PropTypes.bool,
    clientPage: PropTypes.oneOf([
        constants.CLIENT_TYPES.CURRENT_CLIENT,
        constants.CLIENT_TYPES.PREVIOUS_CLIENT
    ]),
    isClientDuplicate: PropTypes.bool,
    clientStatus: PropTypes.string,
    interactionHandler: PropTypes.func
}
