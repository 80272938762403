import { ClientDetailsSmileyIcon, PacketIcon, PaymentIcons } from 'assets'
import { constants, getConnectUser } from 'helpers'
import { useBindDispatch } from 'hooks'
import React, { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ClientStatus, ClientType, Order } from 'storeTypes'
import { InteractionOutcomes } from 'types'

type FarmerIdModalProps = {
    clientData: ClientType
    selectClientHandler: ({
        clientData,
        clientType
    }: {
        clientData: ClientType
        clientType: string
    }) => void
    clientOrders?: Order[]
    closeModalHandler: () => void
}

export const FarmerIdModal: FC<FarmerIdModalProps> = ({
    clientData,
    selectClientHandler,
    clientOrders,
    closeModalHandler
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isClientPrevious = (clientData?.all_enrollment || 0) <= 0
    const { username } = getConnectUser()
    const { sendInteractionsActionHandler } = useBindDispatch()

    const farmerModalClickHandler = (callback: () => void) => {
        closeModalHandler()
        selectClientHandler({
            clientData,
            clientType: isClientPrevious ? 'previous' : 'current'
        })

        callback()
    }

    const interactionHandler = (interactionOutcome: InteractionOutcomes) => {
        sendInteractionsActionHandler({
            client_code: clientData.client_code,
            user_id: username,
            outcome: interactionOutcome,
            client_status: clientData.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: clientData.village_id
        })
    }

    const enrollmentClickHandler = () => {
        const updatedClientData = {
            ...clientData,
            form_type: isClientPrevious ? 're-enrollment' : 'modification',
            ...(isClientPrevious
                ? {}
                : {
                      goal_items: clientOrders?.filter(
                          (item) => !item.delivered
                      ),
                      modification: constants.CLIENT_MODIFICATION_TYPES.PACKAGES
                  })
        }
        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify(updatedClientData)
        )
        if (isClientPrevious) {
            interactionHandler(InteractionOutcomes.ENROLLED)
        }
        console.log('Calling enroll in enrollmentClickHandler')
        navigate(`/enroll/${clientData.village_id}?from=${pathname}`)
    }

    const paymentClickHandler = () => {
        navigate(
            `/payment-selection?from=${pathname}&clientCode=${
                clientData.client_code
            }&villageId=${clientData.village_id}&clientType=${
                isClientPrevious ? 'previous' : ''
            }&clientStatus=${clientData.client_status || ''}`,
            { replace: true }
        )
    }

    const visitClickHandler = () => {
        const visitData = JSON.stringify({
            clientCode: clientData.client_code,
            clientName: clientData.firstname,
            origin: isClientPrevious ? 'prev_client_detail' : 'client_detail',
            villageId: clientData.village_id
        })
        localStorage.setItem(constants.CLIENT_FOR_VISIT_STORE, visitData)
        navigate(`/visit?from=${pathname}`, {
            replace: true
        })
    }

    return (
        <div>
            <div
                className="farmer-id--client-data"
                aria-label="client-details-data"
                onClick={() => {
                    farmerModalClickHandler(() =>
                        navigate(
                            `/village/${clientData.village_id}/client/${
                                clientData.client_code
                            }${
                                isClientPrevious ? '/previous' : ''
                            }?from=${pathname}`,
                            { replace: true }
                        )
                    )
                }}
            >
                <div className="farmer-id--circle"></div>
                <h2 className="farmer-id--name">{clientData.fullname}</h2>
                <p className="farmer-id--client-code">
                    ID: {clientData.client_code}
                </p>
            </div>
            <div className="farmer-id--modal-icons">
                <div className="farmer-id--section-icon">
                    {clientData.client_status !== ClientStatus.FINISHED && (
                        <div
                            className="farmer-id--single-icon"
                            onClick={() => {
                                farmerModalClickHandler(enrollmentClickHandler)
                            }}
                            aria-label="packet-icon-button"
                        >
                            <PacketIcon height="43" />
                        </div>
                    )}
                    {clientData.client_status !== ClientStatus.FINISHED && (
                        <div
                            className="farmer-id--single-icon"
                            onClick={() => {
                                farmerModalClickHandler(paymentClickHandler)
                            }}
                            aria-label="payment-icon-button"
                        >
                            <PaymentIcons />
                        </div>
                    )}
                    <div
                        className="farmer-id--single-icon"
                        onClick={() => {
                            farmerModalClickHandler(visitClickHandler)
                        }}
                        aria-label="client-details-icon-button"
                    >
                        <ClientDetailsSmileyIcon height="43" />
                    </div>
                </div>
            </div>
        </div>
    )
}
