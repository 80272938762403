import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Container, TopBar } from 'components'
import { constants, getConnectUser } from 'helpers'
import { useBindDispatch, useGetClient, useNetworkConnection } from 'hooks'
import { useSelector } from 'react-redux'
import { buildPaymentSummaryProps } from './helpers/buildPaymentSummaryProps'
import { PaymentLoadingSection } from './components/PaymentLoadingSection'
import { PaymentHistoryContent } from './components/PaymentHistoryContent'
import { useGetClientPayments } from './hooks/useGetClientPayments'
import { getClientTransactionDetails } from './helpers/getClientTransactionData'
import { processPaymentsUpdate } from './helpers/processClientTransactionHelpers'
import { isAnyDelivered } from 'helpers/isAnyDelivered'

export const ClientPaymentHistory = () => {
    const { isOnline } = useNetworkConnection()
    const [animate, setAnimate] = useState(false)
    const { villageId: urlVillageId, clientId: urlClientCode } = useParams()
    const { recentCardPayments } = useSelector((state) => state.cardPayment)
    const { transactions } = useSelector(
        (state) => state.transactions[urlVillageId] || {}
    )
    const { paymentData } = useSelector((state) => state.paymentNotification)
    const { clientOrders } = useSelector((state) => state.clientOrders)
    const [searchParams] = useSearchParams()
    const reload = searchParams.get('reload')
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const {
        navbarHandler,
        updateSingleClientBalAndSmsHandler,
        paymentNotificationHandler,
        transactionsHandler,
        getClientOrdersHandler
    } = useBindDispatch()
    const { country, username } = getConnectUser()
    const navigate = useNavigate()
    const { client, loadingClient } = useGetClient(urlVillageId, urlClientCode)
    const clientTransactionsData = useMemo(
        () => client && getClientTransactionDetails(client, transactions),
        [client, transactions?.length]
    )

    const clientPayments = useGetClientPayments(
        paymentData,
        recentCardPayments,
        client?.client_code
    )

    const processedPayments = useMemo(
        () =>
            processPaymentsUpdate(
                clientPayments,
                clientTransactionsData?.transactionList,
                country
            ),
        [
            clientPayments?.length,
            clientTransactionsData?.transactionList?.length,
            country
        ]
    )

    useEffect(() => {
        getClientOrdersHandler(urlClientCode, urlVillageId)
    }, [])

    useEffect(() => {
        setAnimate(true)
        navbarHandler(constants.SHOW_NAVBAR)
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId: urlVillageId,
                clientId: urlClientCode
            })
        }
    }, [reload])

    useEffect(() => {
        paymentNotificationHandler(username)
    }, [recentCardPayments?.length, isOnline])

    useEffect(() => {
        transactionsHandler({ villageId: urlVillageId })
    }, [])

    const backNavigationHandler = () => {
        navigate(`/village/${urlVillageId}/client/${urlClientCode}`)
    }

    const { fullname, all_enrollment: allEnrollment } = client || {}

    const validatedLastVoucherDate = clientTransactionsData?.lastPaymentDate
        ? t('fullDateMonthYear', {
              date: new Date(clientTransactionsData?.lastPaymentDate)
          })
        : '-'

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back backNavigation={backNavigationHandler}>
                        <span className="client-payment-history--header">
                            {fullname}
                        </span>
                    </TopBar>
                </Container>
            </div>
            {loadingClient ? (
                <PaymentLoadingSection
                    loadingText={t('fetchingPaymentLoader')}
                />
            ) : (
                <CSSTransition
                    unmountOnExit
                    timeout={constants.ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimate(true)}
                    nodeRef={nodeRef}
                >
                    <PaymentHistoryContent
                        paymentSummary={buildPaymentSummaryProps(
                            {
                                allEnrollment,
                                balance:
                                    clientTransactionsData?.clientBalance || 0
                            },
                            validatedLastVoucherDate
                        )}
                        isAnyDelivered={isAnyDelivered(clientOrders)}
                        transactions={Object.entries(processedPayments)}
                        country={country}
                        animate={animate}
                    />
                </CSSTransition>
            )}
        </>
    )
}
