import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { replicationState } from 'database/Database'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Card,
    CardClientPayment,
    CardNameAbr,
    ClientPhone,
    Col,
    Container,
    EmptyList,
    Filter,
    FilterOption,
    LastPaymentDate,
    Row,
    Search,
    Spinner,
    Tab,
    TabChild,
    TimedAlert,
    TopBar
} from '../../components'
import { CardPotentialClient } from '../../components/card/CardPotentialClient'
import NoVillagePage from '../../components/noVillage'
import {
    amountFormatter,
    constants,
    filterHandler,
    searchHandler,
    sortHandler,
    buildClientFilters,
    getConnectUser
} from '../../helpers'
import {
    useBindDispatch,
    useNetworkConnection,
    useScreenSize,
    useInfiniteScroll
} from '../../hooks'
import { PartialClientLoader } from './PartialClientLoader'
import {
    buildClientFilterOptions,
    buildPotentialClientFilter,
    buildPotentialClientFilterOptions
} from './villageFilterOptions'

export const VillageClient = () => {
    const { trackEvent } = useMatomo()
    const inputSearchRef = useRef(null)
    const { t } = useTranslation()
    const screenSize = useScreenSize()
    const [clientListData, setClientListData] = useState([])
    const [prevClientListData, setPrevClientListData] = useState([])
    const [prevSearchValue, setPrevSearchValue] = useState({ search: '' })
    const [tabLoader, setTabLoader] = useState(true)
    const [previousClients, setPreviousClients] = useState([])
    const [sortedClientList, setSortedClientList] = useState([])
    const [nbOfIncompleteClients, setNbOfIncompleteClients] = useState(0)
    const [currentSearchValue, setCurrentSearchValue] = useState({ search: '' })
    const [hasUserSearched, setHasUserSearched] = useState(false)
    const [partialLoader, setPartialLoader] = useState(false)
    const [showLoadingText, setShowLoadingText] = useState(false)
    const { isOnline } = useNetworkConnection()
    const {
        MOBILE_SIZE,
        VILLAGE_PAGE_TABS,
        SORT_TYPE,
        SORT_DIRECTION,
        CLIENT_SORT_OPTIONS
    } = constants
    const { openVillagePageData } = useSelector(
        (state) => state.openVillagePage
    )
    const [pageParams, setPageParams] = useState({
        ...openVillagePageData
    })
    const initialActiveTab = pageParams?.clientTab
        ? pageParams.clientTab
        : VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB

    const [activeTab, setActiveTab] = useState({ key: initialActiveTab })
    const {
        villageClientListHandler,
        transactionsHandler,
        openVillagePageHandler,
        navbarHandler,
        clearClientSearchHandler,
        storeVillageOrdersHandler,
        fetchInteractionsHandler
    } = useBindDispatch()
    const { villageId } = useParams()
    const {
        loading: loadingClientList,
        clientList,
        error: clientListError
    } = useSelector((state) => state.villageClientList[villageId] || {})
    const { interactions } = useSelector(
        ({ interactions }) => interactions[villageId] || {}
    )
    const { previousClientList, loading: loadingPreviousClientList } =
        useSelector((state) => state.villagePreviousClientList[villageId] || {})

    const { transactions } = useSelector(
        (state) => state.transactions[villageId] || {}
    )
    const { veVillages, role, veCode } = getConnectUser()
    const navigate = useNavigate()

    // get village name
    const foundVillage = veVillages.find(
        ({ sf_id: sfId }) => sfId === villageId
    )
    const isFc = role?.toLowerCase() === 'fc'
    const showBackBtn = veVillages.length > 1 || !foundVillage || isFc
    const isMobile = screenSize.screenWidth <= MOBILE_SIZE
    const countryConfig = JSON.parse(localStorage.getItem('country_config'))

    // handle scroll position
    const storeScrollPosition = () => {
        localStorage.setItem(
            'scroll_pos',
            JSON.stringify({ scroll: document.documentElement.scrollTop })
        )
    }

    const getPerformanceColorForBalance = (balance, numberOfPayments) => {
        if (!countryConfig?.payment_client_threshold_enabled) {
            return ''
        }

        if (
            numberOfPayments <= 1 ||
            balance <= countryConfig.payment_client_low_threshold
        ) {
            return constants.PERFORMANCE_COLORS.LOW
        }

        if (balance <= countryConfig.payment_client_medium_threshold) {
            return constants.PERFORMANCE_COLORS.MEDIUM
        }

        return constants.PERFORMANCE_COLORS.HIGH
    }

    // handle previous client details page navigation
    const prevClientSelection = (clientId) => {
        // log scroll position
        storeScrollPosition()
        navigate(`/village/${villageId}/client/${clientId}/previous`)
    }

    const createPrevClientCard = (
        {
            client_code: clientCode,
            fullname,
            phone,
            recent_year_delivered: recentYearDelivered,
            balance
        },
        index
    ) => {
        return (
            <CardPotentialClient
                key={index}
                clientCode={clientCode}
                fullname={fullname}
                recentYearDelivered={recentYearDelivered}
                phone={phone}
                balance={balance}
                prevClientSelection={prevClientSelection}
            />
        )
    }

    const createClientCard = (
        {
            calculated_balance: calculatedBalance,
            balance,
            client_code: clientCode,
            fullname,
            phone,
            all_enrollment: allEnrollment,
            certified_for_delivery: certifiedForDelivery,
            last_payment_date: lastPaymentDate,
            transactions
        },
        index
    ) => {
        const processedBalance = calculatedBalance || balance
        const addSeparator = index === nbOfIncompleteClients
        const isPaymentCompleted = parseFloat(processedBalance) >= allEnrollment
        if (isPaymentCompleted) {
            return (
                <div key={clientCode}>
                    {addSeparator && <br />}
                    <Card
                        shadow={false}
                        className="client-contact-card-finisher"
                        data-testid="client-card"
                        onClick={() => clientSelection(clientCode)}
                        id={`client-list-${clientCode}`}
                    >
                        <CardClientPayment
                            paymentCompleted={isPaymentCompleted}
                        >
                            <CardNameAbr
                                subHeader={`ID ${clientCode}`}
                                clientCertified={
                                    certifiedForDelivery === 'True'
                                }
                            >
                                {fullname}
                            </CardNameAbr>
                            <LastPaymentDate>
                                {t('dateMonthYear', {
                                    date: new Date(lastPaymentDate)
                                })}
                            </LastPaymentDate>
                            <ClientPhone>
                                {amountFormatter(parseFloat(phone, ''))}
                            </ClientPhone>
                        </CardClientPayment>
                    </Card>
                </div>
            )
        }
        return (
            <div key={clientCode}>
                {addSeparator && <br />}
                <Card
                    shadow={false}
                    className="client-contact-card"
                    data-testid="client-card"
                    onClick={() => clientSelection(clientCode)}
                    id={`client-list-${clientCode}`}
                >
                    <CardClientPayment lastPaymentDate={lastPaymentDate}>
                        <div className="card-client-data">
                            <CardNameAbr
                                subHeader={`ID ${clientCode}`}
                                performanceColoring={getPerformanceColorForBalance(
                                    processedBalance,
                                    transactions?.length
                                )}
                                balance={amountFormatter(
                                    parseFloat(allEnrollment - processedBalance)
                                )}
                                clientCertified={
                                    certifiedForDelivery === 'True'
                                }
                            >
                                {fullname}
                            </CardNameAbr>
                            {lastPaymentDate && (
                                <LastPaymentDate langTranslate>
                                    {lastPaymentDate}
                                </LastPaymentDate>
                            )}
                        </div>
                        <ClientPhone>
                            {amountFormatter(parseFloat(phone), '')}
                        </ClientPhone>
                    </CardClientPayment>
                </Card>
            </div>
        )
    }

    const { renderedItems: renderedClients, resetItems: resetRenderedClients } =
        useInfiniteScroll({
            filteredItems: sortedClientList,
            createItemCard: createClientCard,
            containerClassName: 'village-client--list'
        })

    const {
        renderedItems: renderedPrevClients,
        resetItems: resetRenderedPrevClients
    } = useInfiniteScroll({
        filteredItems: previousClients,
        createItemCard: createPrevClientCard,
        containerClassName: 'village-client--list'
    })

    const sendSearchAnalyticsEvent = (tab) => {
        trackEvent({
            category:
                tab.key === 0
                    ? constants.ANALYTICS.VILLAGE_POTENTIAL
                    : constants.ANALYTICS.VILLAGE_CLIENT,
            action: constants.ANALYTICS.ACTIONS.SEARCH
        })
    }

    const handleSearchBlur = () => {
        sendSearchAnalyticsEvent(activeTab)
        if (inputSearchRef.current) {
            inputSearchRef.current.removeEventListener('blur', handleSearchBlur)
        }
        setHasUserSearched(false)
    }

    const addSearchOnBlurListener = () => {
        if (inputSearchRef.current) {
            inputSearchRef.current.addEventListener('blur', handleSearchBlur)
        }
    }

    // fetch client list by the villageId
    useEffect(() => {
        // clear client details from local storage
        localStorage.removeItem('client_details')

        villageClientListHandler({ villageId })
        // set page title
        document.title = t('village.pageTitle')

        // display navbar
        navbarHandler(constants.SHOW_NAVBAR)

        // reset any client search result
        clearClientSearchHandler()

        const scrollPosition =
            JSON.parse(localStorage.getItem('scroll_pos'))?.scroll || 0
        document.documentElement.scrollTop = scrollPosition
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoadingText(true)
        }, 2000)

        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        storeVillageOrdersHandler([{ sf_id: villageId }])
        fetchInteractionsHandler({
            villageIds: [villageId],
            surveyNames: [constants.FARMER_AWARENESS_SURVEY_NAME],
            surveyVersion: constants.FARMER_AWARENESS_SURVEY_VERSION
        })
        transactionsHandler({ villageId })
    }, [])

    useEffect(() => {
        if (replicationState) {
            replicationState.active$.subscribe((bool) => {
                setPartialLoader(bool)
            })
        }
    }, [replicationState])

    // reset home client click data
    useEffect(() => {
        if (openVillagePageData?.filterOptions) {
            openVillagePageHandler({
                filterOptions: undefined,
                villageId: '',
                clientTab: ''
            })
        }
        if (pageParams?.clientTab !== activeTab.key) {
            setPageParams()
        }
    }, [openVillagePageData, activeTab])

    // set client list state when client list updates
    useEffect(() => {
        if (clientList) {
            setClientListData(clientList)
        }

        if (previousClientList) {
            setPrevClientListData(previousClientList)
        }

        // filter option from params
        if (pageParams?.filterOptions) {
            filterSelectionHandler([...pageParams.filterOptions])
        }
    }, [clientList, previousClientList])

    useEffect(() => {
        if (tabLoader) {
            setTabLoader(false)
        }
    }, [activeTab])

    useEffect(() => {
        resetInfiniteScroll(activeTab)
        preProcessClientList(clientListData)
        preProcessPreviousClientList(prevClientListData)
    }, [clientListData, prevClientListData, transactions])

    const tabClientConfig = {
        0: {
            data: prevClientListData,
            setData: setPrevClientListData,
            reducerData: previousClientList
        },
        1: {
            data: clientListData,
            setData: setClientListData,
            reducerData: clientList
        }
    }

    const clientsWithOrWithoutPayment = (option) => (client) =>
        !option ? !client.last_voucher_date : client.last_voucher_date

    const sortByPaymentDate = (clientData) => {
        // get clients with last payment date
        let clientsWithPayment = clientData.filter(
            clientsWithOrWithoutPayment(true)
        )

        // get clients without last payment date
        const clientsWithoutPayment = clientData.filter(
            clientsWithOrWithoutPayment(false)
        )
        clientsWithPayment = sortHandler(
            [...clientsWithPayment],
            'last_voucher_date',
            SORT_TYPE.DATE,
            SORT_DIRECTION.ASCENDING
        )
        return [...clientsWithPayment, ...clientsWithoutPayment]
    }

    const lowClients = (client) =>
        getPerformanceColorForBalance(client.balance, client.smss?.length) ===
        constants.PERFORMANCE_COLORS.LOW

    const mediumClients = (client) =>
        getPerformanceColorForBalance(client.balance, client.smss?.length) ===
        constants.PERFORMANCE_COLORS.MEDIUM

    const highClients = (client) =>
        getPerformanceColorForBalance(client.balance, client.smss?.length) ===
        constants.PERFORMANCE_COLORS.HIGH

    const groupClientWithColorCoding = (clients) => {
        const clientsLowThreshold = clients.filter(lowClients)
        const clientsMediumThreshold = clients.filter(mediumClients)
        const clientsHighThreshold = clients.filter(highClients)
        return [
            ...clientsLowThreshold,
            ...clientsMediumThreshold,
            ...clientsHighThreshold
        ]
    }

    const processRemainingPayments = (clientData) => {
        if (countryConfig?.payment_client_threshold_enabled) {
            if (
                countryConfig?.client_sort === CLIENT_SORT_OPTIONS.COLOR_CODING
            ) {
                return groupClientWithColorCoding(clientData)
            }
            if (
                countryConfig?.client_sort ===
                CLIENT_SORT_OPTIONS.REMAINING_PAYMENT
            ) {
                return clientData.sort((a, b) => {
                    const balanceA = a.all_enrollment - a.balance
                    const balanceB = b.all_enrollment - b.balance
                    return balanceA - balanceB
                })
            } else {
                return sortByPaymentDate(clientData)
            }
        }
        return sortByPaymentDate(clientData)
    }

    const preProcessClientList = (currentClientData = []) => {
        const incompleteClientList = []
        const finishersClientList = []

        currentClientData = processRemainingPayments(currentClientData)

        currentClientData.forEach((client) => {
            const processedBalance = client.calculated_balance || client.balance
            parseFloat(processedBalance) >= client.all_enrollment
                ? finishersClientList.push(client)
                : incompleteClientList.push(client)
        })
        setNbOfIncompleteClients(incompleteClientList.length)
        setSortedClientList([...incompleteClientList, ...finishersClientList])
    }

    const preProcessPreviousClientList = (previousClientData = []) => {
        previousClientData = sortHandler(
            [...previousClientData],
            'recent_year_delivered',
            SORT_TYPE.ALPHA_NUMERIC,
            SORT_DIRECTION.ASCENDING
        )
        setPreviousClients(previousClientData)
    }

    // handle client list search
    const clientSearchHandler = (
        e,
        searchList,
        clientReducer,
        clientState,
        searchSetState
    ) => {
        e.preventDefault()
        if (!hasUserSearched) {
            addSearchOnBlurListener()
            setHasUserSearched(true)
        }
        const { value } = e.target
        searchSetState({ search: value })
        if (value.trim().length > 2) {
            searchList = clientReducer
            return clientState(
                searchHandler(searchList, {
                    fullname: value,
                    client_code: value
                })
            )
        }
        clientState(clientReducer)
    }

    const clientFilterOptions = buildClientFilterOptions(t)
    const potentialClientFilterOptions = buildPotentialClientFilterOptions(t)

    // client filter and search render
    const filterSearchRender = (
        searchList,
        resetReducer,
        clientState,
        searchState,
        searchSetState,
        filterOptions
    ) => {
        return (
            <Row>
                <Col col={2} md={5}>
                    <Filter
                        data-testid="filter"
                        filterSelectionHandler={filterSelectionHandler}
                        allOption={t('all')}
                        defaultSelected={
                            pageParams?.clientTab !== activeTab.key
                                ? null
                                : pageParams?.filterOptions
                        }
                        id={`filter-select-${activeTab.key}`}
                    >
                        {filterOptions.map(
                            ({ value, option, hidden = false }) => (
                                <FilterOption
                                    key={value}
                                    value={value}
                                    data-testid="filter-option"
                                    id={`filter-option-${option.replace(
                                        ' ',
                                        '-'
                                    )}`}
                                    visible={!hidden}
                                >
                                    {option}
                                </FilterOption>
                            )
                        )}
                    </Filter>
                </Col>
                <Col col={10} md={7}>
                    <Search
                        placeholder={t('searchInputPlaceholder')}
                        onChange={(e) =>
                            clientSearchHandler(
                                e,
                                searchList,
                                resetReducer,
                                clientState,
                                searchSetState
                            )
                        }
                        inputRef={inputSearchRef}
                        value={searchState.search}
                        data-testid="search-input"
                        id="client-list-search"
                    />
                </Col>
            </Row>
        )
    }

    // handle client selection
    const clientSelection = (clientId) => {
        // log scroll position
        storeScrollPosition()
        navigate(`/village/${villageId}/client/${clientId}`)
    }

    const sendFilterAnalyticsEvent = (tab, filter) => {
        trackEvent({
            category:
                tab.key === 0
                    ? constants.ANALYTICS.VILLAGE_POTENTIAL
                    : constants.ANALYTICS.VILLAGE_CLIENT,
            action: constants.ANALYTICS.ACTIONS.FILTER_LIST,
            customDimensions: [
                {
                    id: 1,
                    value:
                        filter[0] === t('all')
                            ? constants.ANALYTICS.ALL
                            : filter.join(', ')
                }
            ]
        })
    }
    const resetInfiniteScroll = (activeTab) => {
        if (activeTab) {
            if (activeTab.key === VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB) {
                resetRenderedPrevClients()
            } else {
                resetRenderedClients()
            }
        }
    }

    // handle filter option selection
    const filterSelectionHandler = (selectedFilters = []) => {
        if (currentSearchValue?.search || prevSearchValue.search) {
            return
        }
        if (selectedFilters.length === 0) {
            selectedFilters = [t('all')]
        }
        if (selectedFilters.length > 1 || selectedFilters[0] !== t('all')) {
            sendFilterAnalyticsEvent(activeTab, selectedFilters)
        }
        let filteredClientData = []
        const clientFilters = buildClientFilters(interactions, t)
        const potentialClientFilter = buildPotentialClientFilter(t)

        const allActiveTabClient = tabClientConfig[activeTab?.key]?.reducerData

        const clientListFilter = (filterCondition) => {
            if (!allActiveTabClient) {
                return []
            }
            return filterHandler(allActiveTabClient, filterCondition)
        }

        if (activeTab?.key > 0) {
            filteredClientData = []
            const updateClientList = tabClientConfig[activeTab.key].setData
            // get client list
            const clientList = tabClientConfig[activeTab.key].reducerData
            if (selectedFilters.includes(t('all'))) {
                return updateClientList(clientList)
            }
            try {
                selectedFilters.map((singleSelection) => {
                    filteredClientData = [
                        ...filteredClientData,
                        ...clientListFilter(clientFilters[singleSelection])
                    ]
                    return filteredClientData
                })
                filteredClientData = [...new Set(filteredClientData)]
            } catch (error) {}

            updateClientList(filteredClientData)
        } else if (activeTab?.key === 0) {
            filteredClientData = []
            const updatePrevClientList = tabClientConfig[activeTab.key].setData
            // get prev client list
            const prevClientList =
                tabClientConfig[activeTab.key].reducerData || []

            if (selectedFilters.includes(potentialClientFilter.ALL)) {
                return updatePrevClientList(prevClientList)
            }

            if (
                selectedFilters.includes(
                    potentialClientFilter.VISITED_THIS_WEEK
                )
            ) {
                const filterCondition =
                    clientFilters[potentialClientFilter.VISITED_THIS_WEEK]
                return updatePrevClientList(
                    prevClientList.filter(filterCondition)
                )
            }

            selectedFilters.map((singleSelection) => {
                if (
                    // We assume if the selected filter is not one of the predefined filters,
                    // it is a dynamically generated previous year filter.
                    !Object.values(potentialClientFilter).includes(
                        singleSelection
                    )
                ) {
                    const clientListFiltered = clientListFilter(
                        clientFilters.previousYear(singleSelection)
                    )
                    filteredClientData = [
                        ...filteredClientData,
                        ...clientListFiltered
                    ]
                    return filteredClientData
                }

                filteredClientData = [
                    ...filteredClientData,
                    ...clientListFilter(clientFilters[singleSelection])
                ]
                return filteredClientData
            })

            filteredClientData = [...new Set(filteredClientData)]
            updatePrevClientList(filteredClientData)
        }
    }

    const activeCardHandler = () => {
        setTabLoader(true)
    }

    const handleBackBtn = () => {
        if (isFc) {
            navigate(`/fc/village/${villageId}/profile/${veCode}`)
        } else navigate(-1)
    }

    const clientPageRender = (currentClientList = [], prevClientList = []) => {
        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar
                            back={showBackBtn}
                            backNavigation={handleBackBtn}
                        >
                            {foundVillage
                                ? `${t('village.village')} ${
                                      foundVillage?.name
                                  }`
                                : t('error')}
                        </TopBar>
                    </Container>
                </div>
                {foundVillage ? (
                    <div>
                        <Container>
                            <Row>
                                <Col
                                    md={12}
                                    className={isMobile ? 'remove-padding' : ''}
                                >
                                    <Tab
                                        data-testid="tab"
                                        activeTabHandler={setActiveTab}
                                        initialTab={initialActiveTab}
                                    >
                                        <TabChild
                                            title={t('village.potentials')}
                                            data-testid="potential-client-tab"
                                            count={
                                                partialLoader && isOnline
                                                    ? ''
                                                    : loadingPreviousClientList &&
                                                      prevClientList.length <= 0
                                                    ? ''
                                                    : prevClientList.length
                                            }
                                            onClickHandler={activeCardHandler}
                                            id="potential-client-tab"
                                        >
                                            <Container>
                                                {filterSearchRender(
                                                    prevClientList,
                                                    previousClientList,
                                                    setPrevClientListData,
                                                    prevSearchValue,
                                                    setPrevSearchValue,
                                                    potentialClientFilterOptions
                                                )}
                                                {tabLoader ? (
                                                    <Spinner
                                                        size={'50'}
                                                        pageSpinner={false}
                                                    />
                                                ) : prevClientList.length >
                                                  0 ? (
                                                    <Row className="village-client--body">
                                                        <Col
                                                            md={12}
                                                            className="page-wrapper"
                                                        >
                                                            {
                                                                renderedPrevClients
                                                            }
                                                            {partialLoader &&
                                                                isOnline && (
                                                                    <PartialClientLoader />
                                                                )}
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <EmptyList
                                                        message={t(
                                                            'village.noPotentialClient'
                                                        )}
                                                        className="client-empty-list"
                                                    />
                                                )}
                                            </Container>
                                        </TabChild>
                                        <TabChild
                                            title={t('village.clients')}
                                            data-testid="client-tab"
                                            count={
                                                partialLoader && isOnline
                                                    ? ''
                                                    : loadingClientList &&
                                                      currentClientList.length <=
                                                          0
                                                    ? ''
                                                    : currentClientList.length
                                            }
                                            onClickHandler={activeCardHandler}
                                            id="current-client-tab"
                                        >
                                            <Container>
                                                {filterSearchRender(
                                                    currentClientList,
                                                    clientList,
                                                    setClientListData,
                                                    currentSearchValue,
                                                    setCurrentSearchValue,
                                                    clientFilterOptions
                                                )}
                                                {tabLoader ? (
                                                    <Spinner
                                                        size={'50'}
                                                        pageSpinner={false}
                                                    />
                                                ) : currentClientList.length >
                                                  0 ? (
                                                    <Row className="village-client--body">
                                                        <Col
                                                            md={12}
                                                            className="page-wrapper"
                                                        >
                                                            {renderedClients}
                                                            {partialLoader &&
                                                                isOnline && (
                                                                    <PartialClientLoader />
                                                                )}
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <EmptyList
                                                        message={t(
                                                            'village.noActiveClient'
                                                        )}
                                                        className="client-empty-list"
                                                    />
                                                )}
                                            </Container>
                                        </TabChild>
                                    </Tab>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) : (
                    <NoVillagePage text={t('noVillage')} />
                )}
            </>
        )
    }

    const loadingComponent = (loadingClientList || loadingPreviousClientList) &&
        clientList?.length > 0 && (
            <>
                <Spinner
                    size={'90'}
                    pageSpinner
                    loadingMessage={
                        (showLoadingText && t('village.partialLoaderMsg')) || ''
                    }
                />
            </>
        )
    const clientErrorComponent = clientListError && (
        <TimedAlert type="floating" status="error">
            {clientListError}
        </TimedAlert>
    )

    const villageClients =
        loadingComponent ||
        clientErrorComponent ||
        clientPageRender(clientListData, prevClientListData)

    return villageClients
}
