export enum VesActionEnum {
    FETCHING_VES,
    FETCH_VES_SUCCESS,
    FETCH_VES_FAILURE,
    FETCH_VES_COMPLETE
}

export enum VeActionEnum {
    FETCHING_VE = 'fetching ve',
    FETCH_VE_SUCCESS = 'fetch ve success',
    FETCH_VE_FAILURE = 'fetch ve failure'
}

export type VesActionType = {
    type: VesActionEnum
    payload?: string | VeType[]
}

export type VeActionType = {
    type: VeActionEnum
    payload?: VeType | null
}

export type VeType = {
    code: string
    current_village_codes: string
    current_villages: string
    ve_villages: { code: string; name: string; sf_id: string }[]
    name: string
    zone: string
    zone_code: string
    zone_name: string
    phone_flotte: string
    is_active: boolean
} & VeMetricsType

export type VeMetricsType = {
    clients_active_this_month: number
    clients_enrolled_this_month: number
    finishers_this_season: number
    sales_target_this_season: number
    sales_this_month: number
    target_clients_active_this_month: number
    target_clients_registered_this_week: number
    target_finishers_this_season: number
    target_monthly_enrolled: number
    target_monthly_sales: number
    target_weekly_visited: number
    total_clients_isp: number
    total_clients_psi: number
    total_clients_registered_this_week: number
    total_sales_this_season: number
    ve_target_clients_enrolled: number
    visits_this_week: number
}
