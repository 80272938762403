import { NotAvailableIcon } from 'assets'
import React from 'react'
import './emptyList.scss'

type EmptyListProps = {
    icon?: JSX.Element
    message: string
    className?: string
}
export const EmptyList = ({ icon, message, className }: EmptyListProps) => {
    return (
        <div className={`empty-list ${className || ''}`}>
            <div className="empty-list--icon" data-testid="not-available-icon">
                {icon || <NotAvailableIcon />}
            </div>
            <div className="empty-list--text">
                <p>{message}</p>
            </div>
        </div>
    )
}
