import { filledPhone } from 'assets'
import { Button } from 'components/button/Button'
import React from 'react'

export const ClientPhone = ({
    clientPhoneInfo,
    permissions
}: {
    clientPhoneInfo: { formatPhone: string; clientPhone: string }
    clientInteractionStatus: {
        btnStyle: 'info' | 'light' | 'warning'
        color: string
        className: string
    }
    permissions: {
        isClientDuplicate: boolean
        canCallClient: boolean
    }
    modifyHandler: () => void
}) => {
    return (
        <>
            <Button
                className={`card-client-detail--${
                    permissions.isClientDuplicate
                        ? 'client-phone-duplicate'
                        : 'client-phone'
                }`}
                style="solid"
                icon={filledPhone({
                    color: permissions.isClientDuplicate ? '#979797' : '#16A34A'
                })}
                iconPos="left"
                id="client-details-phone-btn"
                data-testid="phone-button"
            >
                <a
                    href={
                        permissions?.canCallClient
                            ? `tel:${clientPhoneInfo?.clientPhone}`
                            : undefined
                    }
                    className={[
                        'phone-number-text',
                        `phone-number-text--${
                            permissions?.isClientDuplicate
                                ? 'duplicate'
                                : 'normal'
                        }`
                    ].join(' ')}
                    aria-label="phone-number-link"
                >
                    {clientPhoneInfo?.formatPhone}
                </a>
            </Button>
        </>
    )
}
