import React, { FC } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { FcHome } from 'fc/pages/home/FcHome'
import { FcProfile, FcVillage, VillageProfile, VeProfile } from 'fc/pages'
import {
    ClientPaymentHistory,
    VillageClient,
    VillageClientDetails,
    VillagePrevClientDetails,
    CardSale,
    Visit,
    GPSWarning
} from 'pages'
import { FcSearchResults } from 'fc/pages/search/FcSearchResults'
import EnrollmentBase from 'pages/enrollment/v3/EnrollmentBase'

const ConnectRoutes = () => {
    const VillagePrevClientDetailsPage = VillagePrevClientDetails as FC

    return (
        <>
            <Route path="/" element={<Navigate replace to="/fc/home" />} />
            <Route path="/fc" element={<Outlet />}>
                <Route path="home" element={<FcHome />} />
                <Route path="profile" element={<FcProfile />} />
                <Route path="village" element={<Outlet />}>
                    <Route path="" element={<FcVillage />} />
                    <Route
                        path=":veVillageId/profile/:veCode"
                        element={<VillageProfile />}
                    />
                    <Route path="ve/:veCode/profile" element={<VeProfile />} />
                </Route>
                <Route path="village" element={<FcVillage />} />
                <Route path="ve-search/results" element={<FcSearchResults />} />
            </Route>
            <Route path="village" element={<Outlet />}>
                <Route path=":villageId" element={<VillageClient />} />
                <Route path=":villageId" element={<Outlet />}>
                    <Route
                        path="client/:clientId"
                        element={<VillageClientDetails />}
                    />
                    <Route path="client/:clientId" element={<Outlet />}>
                        <Route
                            path="payment-history"
                            element={<ClientPaymentHistory />}
                        />
                        <Route
                            path="previous"
                            element={<VillagePrevClientDetailsPage />}
                        />
                    </Route>
                </Route>
            </Route>
            <Route path="enroll/:villageId" element={<EnrollmentBase />} />
            <Route path="cards" element={<Outlet />}>
                <Route path="card-sale" element={<CardSale />} />
            </Route>
            <Route path="visit" element={<Visit />} />
            <Route path="gpswarning" element={<GPSWarning />} />
        </>
    )
}

export default ConnectRoutes
