import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useBindDispatch } from 'hooks'
import {
    Container,
    Spinner,
    TopBar,
    SearchableTab,
    DownloadVillageCard
} from 'components'

import { colors } from 'styles/colors'
import { getConnectUser, searchBy, VillageType } from 'helpers'
import { NetworkBarSvgs, VeIcon } from 'assets'
import { VeType } from 'storeTypes/vesTypes'
import { DownloadVeCard } from 'components/card/DownloadVeCard'
import { useNavigate } from 'react-router-dom'

export const FcVillage = () => {
    const { VillagesIcon } = NetworkBarSvgs
    const { t } = useTranslation()
    const fcData = useAppSelector((state) => state.fcMetrics.data)
    const navigate = useNavigate()

    const {
        ves,
        villages,
        loading: loadingVes
    } = useAppSelector((state) => state.ves)
    const { staff_code: staffCode } = getConnectUser()
    const { fetchVesHandler, fcMetricsHandler } = useBindDispatch()

    useEffect(() => {
        if (!fcData && staffCode) {
            fcMetricsHandler(staffCode)
        }
    }, [fcData, staffCode])

    useEffect(() => {
        if (fcData?.fc_info?.zone_code) {
            fetchVesHandler(fcData.fc_info.zone_code)
        }
    }, [fcData])

    if (!staffCode || !fcData || loadingVes) {
        return <Spinner size="90" pageSpinner={true} />
    }

    const veOpenClickHandler = (ve: VeType) => {
        navigate(`/fc/village/ve/${ve.code}/profile?from_tab=1`)
    }

    const villageOpenClickHandler = (village: VillageType) => {
        if (!village.ves?.length) return
        navigate(`${village.sf_id}/profile/${village.ves[0].code}`)
    }

    return (
        <div>
            <Container>
                <TopBar iconColor={colors.black} transparentTopBar>
                    {t('zone')} {fcData.fc_info.zone_name}
                </TopBar>
            </Container>
            <div className="page-wrapper">
                <SearchableTab
                    tabs={[
                        {
                            text: t('village.villages'),
                            placeholderText: t(
                                'village.villageSearchPlaceholder'
                            ),
                            noResultsText: t('village.villageNoResults'),
                            icon: (
                                <VillagesIcon
                                    width="21"
                                    height="21"
                                    color={colors.grey}
                                />
                            ),
                            extraClass: 'green',
                            items: villages,
                            renderItem: (village) => (
                                <DownloadVillageCard
                                    village={village as VillageType}
                                    openClickHandler={villageOpenClickHandler}
                                    downloadErrorMsg={t('downloadErrorText')}
                                />
                            ),
                            searchItems: (villages, query) =>
                                searchBy(
                                    villages as VillageType[],
                                    ['name', 'code', 'ves'],
                                    query
                                )
                        },
                        {
                            text: t('village.ves'),
                            placeholderText: t('village.veSearchPlaceholder'),
                            noResultsText: t('village.veNoResults'),
                            icon: (
                                <VeIcon
                                    width="21"
                                    height="21"
                                    color={colors.grey}
                                />
                            ),
                            extraClass: 'blue',
                            items: ves,
                            renderItem: (ve) => (
                                <DownloadVeCard
                                    ve={ve as VeType}
                                    openClickHandler={veOpenClickHandler}
                                    downloadErrorMsg={t('downloadErrorText')}
                                />
                            ),
                            searchItems: (ves, query) =>
                                searchBy(
                                    ves as VeType[],
                                    ['name', 'code', 'current_villages'],
                                    query
                                )
                        }
                    ]}
                />
            </div>
        </div>
    )
}
